import { Info, StringUnitLength } from 'luxon';

import { CALENDAR_FIRST_DAY_VIEW } from '../enums';
import { WeekDay } from '../models';

export namespace TIME {
    export const MILLS_IN_SECOND: number = 1000;
    export const SECONDS_IN_MINUTE: number = 60;
    export const MINUTES_IN_HOUR: number = 60;
    export const HOURS_IN_DAY: number = 24;
    export const DAYS_IN_WEEK: number = 7;
    export const MAX_DAYS_IN_MONTH: number = 31;
    export const MS_PER_MINUTE: number = MILLS_IN_SECOND * SECONDS_IN_MINUTE;
    export const MS_PER_HOUR: number = MS_PER_MINUTE * MINUTES_IN_HOUR;
    export const MS_PER_DAY: number = MS_PER_HOUR * HOURS_IN_DAY;

    // eslint-disable-next-line no-inner-declarations
    function getWeekDayNumbers(startOfWeek: CALENDAR_FIRST_DAY_VIEW = CALENDAR_FIRST_DAY_VIEW.MONDAY): number[] {
        return Array.from({ length: DAYS_IN_WEEK }, (value: undefined, index: number) => {
            const dayIndex: number = startOfWeek + index;
            const correctedIndex: number = dayIndex > DAYS_IN_WEEK - 1 ? dayIndex % DAYS_IN_WEEK : dayIndex;
            return correctedIndex;
        });
    }

    export function convertDateTimeWeekDayToNative(dateTimeWeekDay: number): number {
        // JS: Sunday - Saturday : 0 - 6
        // luxon: Monday - Sunday: 1-7
        return dateTimeWeekDay === DAYS_IN_WEEK ? 0 : dateTimeWeekDay;
    }

    export function convertNativeWeekDayToDateTime(nativeWeekDay: number): number {
        // JS: Sunday - Saturday : 0 - 6
        // luxon: Monday - Sunday: 1-7
        return nativeWeekDay === 0 ? DAYS_IN_WEEK : nativeWeekDay;
    }

    export function convertNativeWeekDayToDayOfWeek(nativeWeekDay: number): number {
        // JS: Sunday - Saturday : 0 - 6
        // java.time.DayOfWeek: Monday - Sunday: 1-7
        return nativeWeekDay === 0 ? DAYS_IN_WEEK : nativeWeekDay;
    }

    export function getWeekDays(
        startOfWeek: CALENDAR_FIRST_DAY_VIEW = CALENDAR_FIRST_DAY_VIEW.MONDAY,
        length: StringUnitLength = 'short'
    ): WeekDay[] {
        // JS: Sunday - Saturday : 0 - 6
        // luxon: Monday - Sunday: 1-7
        const weekdays: string[] = [...Info.weekdays(length, { locale: 'en-US' })]; // [Mon, ..., Sun]
        const weekDayNumbers: number[] = getWeekDayNumbers(startOfWeek);

        return weekDayNumbers.map((weekDayNumber: number) => ({
            name: weekdays[weekDayNumber === 0 ? weekdays.length - 1 : weekDayNumber - 1],
            value: weekDayNumber,
        }));
    }

    export function sortWeekDays(weekDays: number[], startOfWeek: CALENDAR_FIRST_DAY_VIEW): number[] {
        return [...weekDays].sort((d1: number, d2: number): number => compareWeekDays(d1, d2, startOfWeek));
    }

    export function lastDayOfWeek(weekDays: number[], startOfWeek: number): number {
        return sortWeekDays(weekDays, startOfWeek)[weekDays.length - 1];
    }

    export function compareWeekDays(d1: number, d2: number, startOfWeek: CALENDAR_FIRST_DAY_VIEW): number {
        if (d1 >= startOfWeek && d2 < startOfWeek) {
            return -1;
        }

        if (d1 < startOfWeek && d2 >= startOfWeek) {
            return 1;
        }

        return d1 - d2;
    }

    export function weekDaysDelta(d1: number, d2: number, startOfWeek: CALENDAR_FIRST_DAY_VIEW): number {
        const delta: number =
            d1 >= startOfWeek
                ? d2 >= startOfWeek
                    ? d2 - d1
                    : DAYS_IN_WEEK - d1 + d2
                : d2 >= startOfWeek
                  ? DAYS_IN_WEEK - d2 + d1
                  : d2 - d1;

        return delta;
    }

    export function endOfWeek(startOfWeek: CALENDAR_FIRST_DAY_VIEW): number {
        return startOfWeek >= 1 ? startOfWeek - 1 : DAYS_IN_WEEK - 1;
    }

    export function dateDiffInDays(a: Date, b: Date): number {
        const utc1: number = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2: number = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
        return Math.floor((utc2 - utc1) / MS_PER_DAY);
    }

    export function addDays(date: Date, days: number): Date {
        const result: Date = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
}
