import { Pipe, PipeTransform } from '@angular/core';

import { ContactAttributeDto } from '../dtos';

@Pipe({
    name: 'faxContactAttr',
})
export class FaxPipe implements PipeTransform {
    public transform(attributes: ContactAttributeDto[] = []): ContactAttributeDto['value'] {
        const faxAttribute = attributes.find((attribute) => attribute.attributeType === 'phone' && attribute.attributeLabel === 'Work fax');
        return faxAttribute ? faxAttribute.value : 'No Fax';
    }
}
