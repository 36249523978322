import { Pipe, PipeTransform } from '@angular/core';

import { ContactAttributeDto } from '../dtos';

@Pipe({
    name: 'filterAttribute',
})
export class FilterAttributePipe implements PipeTransform {
    public transform(attributes: ContactAttributeDto[] | undefined, attributeType: string): ContactAttributeDto[] {
        return (attributes ?? []).filter((attr) => attr.attributeType === attributeType);
    }
}
