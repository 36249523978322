import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';

@Injectable()
export class ComponentWiperService {
    readonly #destroyRef: DestroyRef = inject(DestroyRef);

    public imperative<T>(
        observable: Observable<T>,
        ...subscribeArgs: Parameters<Observable<T>['subscribe']>
    ): ReturnType<Observable<T>['subscribe']> {
        return observable.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(...subscribeArgs);
    }
}
