import { DateTime, Duration } from 'luxon';

import { TIME } from './time';

export namespace CalendarOptionConstants {
    export enum MERIDIEM_TIME_FORMAT {
        _1PM = '1:00PM',
        _13 = '13:00',
    }

    export enum SLOT_VIEW {
        M15 = '00:15:00',
        M30 = '00:30:00',
        M60 = '01:00:00',
        M90 = '01:30:00',
        M120 = '02:00:00',
    }
}

export type TimepickerFormatType = 12 | 24;

export namespace TimeOptionUtils {
    export const formatTimePicker = new Map<CalendarOptionConstants.MERIDIEM_TIME_FORMAT, TimepickerFormatType>([
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        [CalendarOptionConstants.MERIDIEM_TIME_FORMAT._13, 24], // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        [CalendarOptionConstants.MERIDIEM_TIME_FORMAT._1PM, 12],
    ]);

    export const formatDateTime = new Map<CalendarOptionConstants.MERIDIEM_TIME_FORMAT, string>([
        [CalendarOptionConstants.MERIDIEM_TIME_FORMAT._13, 'HH:mm'],
        [CalendarOptionConstants.MERIDIEM_TIME_FORMAT._1PM, 'h:mm a'],
    ]);

    /** {@link https://moment.github.io/luxon/#/parsing?id=table-of-tokens|Table of tokens} */
    export const dateTimeFormatterToken = new Map<CalendarOptionConstants.MERIDIEM_TIME_FORMAT, string>([
        [CalendarOptionConstants.MERIDIEM_TIME_FORMAT._13, 'HH:mm'],
        [CalendarOptionConstants.MERIDIEM_TIME_FORMAT._1PM, 'h:mm a'],
    ]);

    export function fromTimePickerRangeToMsRange(
        pickerStartTime: string,
        pickerEndTime: string,
        meridiemTimeFormat: CalendarOptionConstants.MERIDIEM_TIME_FORMAT
    ): [number, number] {
        const startDt: DateTime = TimeOptionUtils.fromTimePickerTime(pickerStartTime, meridiemTimeFormat);
        const endDt: DateTime = TimeOptionUtils.fromTimePickerTime(pickerEndTime, meridiemTimeFormat);
        const startTime: number = startDt.toMillis() - startDt.startOf('day').toMillis();
        const endTime: number = endDt.toMillis() - endDt.startOf('day').toMillis();

        return endTime >= startTime ? [startTime, endTime] : [startTime, endTime + TIME.MS_PER_DAY];
    }

    export function fromDayAndTimePickerTime(
        date: Date,
        pickerStartTime: string,
        pickerEndTime: string,
        meridiemTimeFormat: CalendarOptionConstants.MERIDIEM_TIME_FORMAT
    ): [number, number] {
        const [startTimeMs, endTimeMs] = fromTimePickerRangeToMsRange(pickerStartTime, pickerEndTime, meridiemTimeFormat);
        const dayMs: number = DateTime.fromJSDate(date).startOf('day').toMillis();
        return [dayMs + startTimeMs, dayMs + endTimeMs];
    }

    export function fromTimePickerTime(time: string, meridiemTimeFormat: CalendarOptionConstants.MERIDIEM_TIME_FORMAT): DateTime {
        return DateTime.fromFormat(time, dateTimeFormatterToken.get(meridiemTimeFormat)!);
    }

    export function formatTimePickerTime(dt: DateTime, meridiemTimeFormat: CalendarOptionConstants.MERIDIEM_TIME_FORMAT): string {
        return dt.toFormat(dateTimeFormatterToken.get(meridiemTimeFormat)!);
    }

    export function millisecondsToHours(millis: number, precision: number = 2): number {
        const duration: Duration = Duration.fromMillis(millis).shiftTo('minutes');

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return Math.round((duration.minutes / TIME.MINUTES_IN_HOUR) * Math.pow(10, precision)) / Math.pow(10, precision);
    }

    export function millisecondsToFixedHours(millis: number): string {
        const precision: number = 2;
        return millisecondsToHours(millis, precision).toFixed(precision);
    }

    export function getHoursDurationFormatted(fromMillis: number, toMillis: number): string {
        return millisecondsToFixedHours(fromMillis - toMillis);
    }
}
