import { patchState, signalStoreFeature, withMethods, withState } from '@ngrx/signals';
import { Observable, Subject } from 'rxjs';

interface CrudEventsState {
    creatingEntity: boolean;
    readingEntities: boolean;
    readingEntity: boolean;
    updatingEntity: boolean;
    deletingEntities: boolean;
    deletingEntity: boolean;
}

const initialState: CrudEventsState = {
    creatingEntity: false,
    readingEntities: false,
    readingEntity: false,
    updatingEntity: false,
    deletingEntities: false,
    deletingEntity: false,
};

export function withCrudEvents<Entity, FailedError = string>() {
    return signalStoreFeature(
        withState<CrudEventsState>(initialState),
        withMethods(
            (
                store,

                createEntitySuccessSource = new Subject<Entity>(),
                createEntityFailedSource = new Subject<FailedError>(),

                readEntitiesSuccessSource = new Subject<Entity[]>(),
                readEntitiesFailedSource = new Subject<FailedError>(),

                readEntitySuccessSource = new Subject<Entity>(),
                readEntityFailedSource = new Subject<FailedError>(),

                updateEntitySuccessSource = new Subject<Entity>(),
                updateEntityFailedSource = new Subject<FailedError>(),

                deleteEntitiesSuccessSource = new Subject<void>(),
                deleteEntitiesFailedSource = new Subject<FailedError>(),

                deleteEntitySuccessSource = new Subject<void>(),
                deleteEntityFailedSource = new Subject<FailedError>()
            ) => ({
                _createEntitySuccess: (data: Entity): void => {
                    patchState(store, { creatingEntity: false });
                    createEntitySuccessSource.next(data);
                },
                onCreateEntitySuccess: (): Observable<Entity> => createEntitySuccessSource.asObservable(),
                _createEntityFailed: (data: FailedError): void => {
                    patchState(store, { creatingEntity: false });
                    createEntityFailedSource.next(data);
                },
                onCreateEntityFailed: (): Observable<FailedError> => createEntityFailedSource.asObservable(),

                _readEntitiesSuccess: (data: Entity[]): void => {
                    patchState(store, { readingEntities: false });
                    readEntitiesSuccessSource.next(data);
                },
                onReadEntitiesSuccess: (): Observable<Entity[]> => readEntitiesSuccessSource.asObservable(),
                _readEntitiesFailed: (data: FailedError): void => {
                    patchState(store, { readingEntities: false });
                    readEntitiesFailedSource.next(data);
                },
                onReadEntitiesFailed: (): Observable<FailedError> => readEntitiesFailedSource.asObservable(),

                _readEntitySuccess: (data: Entity): void => {
                    patchState(store, { readingEntity: false });
                    readEntitySuccessSource.next(data);
                },
                onReadEntitySuccess: (): Observable<Entity> => readEntitySuccessSource.asObservable(),
                _readEntityFailed: (data: FailedError): void => {
                    patchState(store, { readingEntity: false });
                    readEntityFailedSource.next(data);
                },
                onReadEntityFailed: (): Observable<FailedError> => readEntityFailedSource.asObservable(),

                _updateEntitySuccess: (data: Entity): void => {
                    patchState(store, { updatingEntity: false });
                    updateEntitySuccessSource.next(data);
                },
                onUpdateEntitySuccess: (): Observable<Entity> => updateEntitySuccessSource.asObservable(),
                _updateEntityFailed: (data: FailedError): void => {
                    patchState(store, { updatingEntity: false });
                    updateEntityFailedSource.next(data);
                },
                onUpdateEntityFailed: (): Observable<FailedError> => updateEntityFailedSource.asObservable(),

                _deleteEntitiesSuccess: (): void => {
                    patchState(store, { deletingEntities: false });
                    deleteEntitiesSuccessSource.next();
                },
                onDeleteEntitiesSuccess: (): Observable<void> => deleteEntitiesSuccessSource.asObservable(),
                _deleteEntitiesFailed: (data: FailedError): void => {
                    patchState(store, { deletingEntities: false });
                    deleteEntitiesFailedSource.next(data);
                },
                onDeleteEntitiesFailed: (): Observable<FailedError> => deleteEntitiesFailedSource.asObservable(),

                _deleteEntitySuccess: (): void => {
                    patchState(store, { deletingEntity: false });
                    deleteEntitySuccessSource.next();
                },
                onDeleteEntitySuccess: (): Observable<void> => deleteEntitySuccessSource.asObservable(),
                _deleteEntityFailed: (data: FailedError): void => {
                    patchState(store, { deletingEntity: false });
                    deleteEntityFailedSource.next(data);
                },
                onDeleteEntityFailed: (): Observable<FailedError> => deleteEntityFailedSource.asObservable(),
            })
        )
    );
}
