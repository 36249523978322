import { Pipe, PipeTransform } from '@angular/core';

import { SupplierDto } from '../dtos';

@Pipe({
    name: 'supplierJobName',
})
export class SupplierJobNamePipe implements PipeTransform {
    public transform(supplier: SupplierDto): string {
        const { jobTitle, companyName } = supplier;
        if (jobTitle && companyName) {
            return `${jobTitle}, ${companyName}`;
        }
        if (jobTitle) {
            return jobTitle;
        }
        if (companyName) {
            return companyName;
        }
        return '';
    }
}
