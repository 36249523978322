import { Pipe, PipeTransform } from '@angular/core';

import { UserDto } from '../dtos';

@Pipe({
    name: 'userStatus',
})
export class UserStatusPipe implements PipeTransform {
    public transform(user: UserDto): string {
        return user.active ? 'Active' : 'Suspended';
    }
}
