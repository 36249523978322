import { Pipe, PipeTransform } from '@angular/core';

import { ContactAddressDto } from '../dtos';
import { AddressLabelEnum } from '../enums';

const noAddress = 'No address';

@Pipe({
    name: 'locationCity',
})
export class LocationCityPipe implements PipeTransform {
    public transform(addresses: ContactAddressDto[] | undefined): string {
        if (!addresses) return noAddress;

        const officeAddress = addresses.find((address) => address.label === AddressLabelEnum.office);
        if (officeAddress) {
            return officeAddress.city?.trim() ? officeAddress.city : noAddress;
        }

        const mailingAddress = addresses.find((address) => address.label === AddressLabelEnum.mailing);
        return mailingAddress?.city?.trim() ? mailingAddress.city : noAddress;
    }
}
