import { Pipe, PipeTransform } from '@angular/core';

import { FileType } from '../enums';
import { getFileType } from './file-type';

@Pipe({
    name: 'fileType',
})
export class FileTypePipe implements PipeTransform {
    public transform(extension: string | undefined): FileType {
        return getFileType(extension);
    }
}
