export namespace MaxLength {
    export const LABEL_NAME_MAX_LENGTH = 30;
    export const ABBREVIATION_CODE_MAX_LENGTH = 2;
    export const GROUP_NAME_MAX_LENGTH = 20;
    export const NOTE_MAX_LENGTH = 2000;
    export const STREET_MAX_LENGTH = 100;
    export const PO_BOX_MAX_LENGTH = 100;
    export const CITY_MAX_LENGTH = 64;
    export const POSTAL_CODE_MAX_LENGTH = 10;
    export const EMPLOYEE_ID_MAX_LENGTH = 10;
    export const CASE_FILE_RELATIONSHIP_TYPE_MAX_LENGTH = 100;
    export const BILLING_ITEM_NAME_MAX_LENGTH = 100;
    export const BILLING_ITEM_DESCRIPTION_MAX_LENGTH = 500;
    export const BILLING_ITEM_SKU_MAX_LENGTH = 16;
    export const BILLING_ITEM_UPC_MAX_LENGTH = 13;
    export const BILLING_ITEM_BRAND_NAME_MAX_LENGTH = 50;
    export const BILLING_ITEM_MODEL_NUMBER_MAX_LENGTH = 32;
    export const BILLING_ITEM_RATE_MAX_LENGTH = 7;

    export const CONTACT_PREFIX_MAX_LENGTH = 10;
    export const CONTACT_LAST_NAME_MAX_LENGTH = 70;
    export const CONTACT_FIRST_NAME_MAX_LENGTH = 70;
    export const CONTACT_MIDDLE_NAME_MAX_LENGTH = 70;
    export const CONTACT_SUFFIX_MAX_LENGTH = 10;
    export const CONTACT_PREFERRED_NAME_MAX_LENGTH = 70;
    export const CONTACT_COMPANY_NAME_MAX_LENGTH = 100;
    export const CONTACT_JOB_TITLE_MAX_LENGTH = 100;

    export const CLOSE_CASE_FILE_NOTE_MAX_LENGTH = 300;
}
