import { Pipe, PipeTransform } from '@angular/core';

import { ContactAttributeDto } from '../dtos';

@Pipe({
    name: 'filterAttributeType',
})
export class FilterAttributeTypePipe implements PipeTransform {
    public transform(attributes: ContactAttributeDto[], attributeType: string): ContactAttributeDto[] {
        return attributes.filter((attr) => attr.attributeType === attributeType);
    }
}
