import { Pipe, PipeTransform } from '@angular/core';

import { BillingItemDto, RateTierDto, RateTierType } from '../dtos';

@Pipe({
    name: 'billingItemRateTierValue',
})
export class BillingItemRateTierValuePipe implements PipeTransform {
    public transform(item: BillingItemDto, rateTierType: RateTierType): number | null {
        const rateTier = item.rateTiers!.find((rateTier) => rateTier.rateTierType === rateTierType);

        if (rateTier?.rateTierHistory) {
            const rate = this.getLastRateValue(rateTier);
            return rate ?? null;
        }

        return null;
    }

    private getLastRateValue(rateTier: RateTierDto) {
        const length = rateTier.rateTierHistory!.length;

        if (length > 0) {
            return rateTier.rateTierHistory![length - 1].rate;
        }

        return null;
    }
}
