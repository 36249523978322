import { CurrencyPipe } from '@angular/common';
import { inject, Pipe, PipeTransform, Provider } from '@angular/core';

import { LocalizationSource } from '../services';
import { CURRENCY_PIPE_CONFIG } from '../tokens/currency-pipe-config.token';
import { LocalizationUtils } from '../utils';

export interface CurrencyPipeConfig {
    currencyCode: LocalizationUtils.CurrencyEnum;
}

@Pipe({
    name: 'localizedCurrency',
})
export class LocalizedCurrencyPipe implements PipeTransform {
    private readonly currencyPipe = inject(CurrencyPipe);
    private readonly currencyPipeConfig = inject(CURRENCY_PIPE_CONFIG);

    public transform(...args: Parameters<typeof CurrencyPipe.prototype.transform>): ReturnType<typeof CurrencyPipe.prototype.transform> {
        const newArgs: Parameters<typeof CurrencyPipe.prototype.transform> = [
            args[0],
            this.currencyPipeConfig.currencyCode,
            args[2] ?? 'symbol-narrow',
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            args[3],
        ];

        return this.currencyPipe.transform.call(this.currencyPipe, ...newArgs);
    }
}

export function provideLocalizedCurrencyPipe(): Provider[] {
    return [
        LocalizationSource,
        {
            provide: CURRENCY_PIPE_CONFIG,
            deps: [LocalizationSource, CurrencyPipe],
            useFactory: (localizationSource: LocalizationSource): CurrencyPipeConfig =>
                Object.create(
                    {},
                    {
                        currencyCode: {
                            get() {
                                return localizationSource.getCurrencyCode();
                            },
                        },
                    }
                ),
        },

        {
            provide: CurrencyPipe,
            useClass: LocalizedCurrencyPipe,
        },
    ];
}
