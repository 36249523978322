import { Pipe, PipeTransform } from '@angular/core';

import { ContactAttributeDto } from '../dtos';

@Pipe({
    name: 'customPhone',
})
export class CustomPhonePipe implements PipeTransform {
    public transform(attributes: ContactAttributeDto[]): ContactAttributeDto['value'] {
        const phoneAttribute = attributes.find((attribute) => attribute.attributeType === 'phone');
        return phoneAttribute ? phoneAttribute.value : 'No Phone';
    }
}
