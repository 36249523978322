import { Pipe, PipeTransform } from '@angular/core';

const MILLISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;

@Pipe({
    name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
    public transform(value: string | number | Date): string {
        if (!value) return 'Invalid date';
        const date = new Date(value);
        const now = new Date();
        const seconds = Math.floor((now.getTime() - date.getTime()) / MILLISECONDS_IN_SECOND);

        if (seconds < SECONDS_IN_MINUTE) {
            return seconds === 1 ? '1 second ago' : `${seconds} seconds ago`;
        }

        const minutes = Math.floor(seconds / SECONDS_IN_MINUTE);
        if (minutes < MINUTES_IN_HOUR) {
            return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
        }

        const hours = Math.floor(minutes / MINUTES_IN_HOUR);
        if (hours < HOURS_IN_DAY) {
            return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
        }

        const days = Math.floor(hours / HOURS_IN_DAY);
        return days === 1 ? '1 day ago' : `${days} days ago`;
    }
}
