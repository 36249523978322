import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'age',
})
export class AgePipe implements PipeTransform {
    public transform(value: string | number): number | string {
        if (!value) return '';

        const timestamp = typeof value === 'number' ? value : parseInt(value, 10);
        if (isNaN(timestamp)) return 'Invalid date';

        const birthDate = new Date(timestamp);
        const today = new Date();
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }
}
