import { Pipe, PipeTransform } from '@angular/core';

import { ContactGroupsDto } from '../dtos';

@Pipe({
    name: 'hasNonReservedGroups',
})
export class HasNonReservedGroupsPipe implements PipeTransform {
    public transform(groups: ContactGroupsDto[] | undefined): boolean {
        return groups?.some((group) => !group.reserved) ?? false;
    }
}
