import { Pipe, PipeTransform } from '@angular/core';

import { CaseFileDto } from '../dtos';

@Pipe({
    name: 'caseFileDisplay',
})
export class CaseFileDisplayPipe implements PipeTransform {
    public transform(caseFile: CaseFileDto): string {
        const status = caseFile.status === 'CLOSED' ? 'Closed' : '';

        return `${caseFile.caseFileName} (${caseFile.publicId})  ${status}`;
    }
}
