import { Pipe, PipeTransform } from '@angular/core';

import { UserRoles } from '../dtos';

@Pipe({
    name: 'hasRole',
})
export class HasRolePipe implements PipeTransform {
    public transform(roles: UserRoles[], role: UserRoles): boolean {
        return roles.includes(role);
    }
}
