import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import { CaseContactDto, Page } from '../../../dtos';

export class CaseContactResource {
    private readonly _api = inject(HttpClient);

    public createCaseContact(caseContactDto: CaseContactDto): Observable<CaseContactDto> {
        return this._api.post<CaseContactDto>(`casefile/v1/case-contacts`, caseContactDto);
    }

    public getCaseContacts(params?: { searchText?: string; page?: number; size?: number }): Observable<Page<CaseContactDto>> {
        return this._api.get<Page<CaseContactDto>>(`casefile/v1/case-contacts`, { params: { ...params } });
    }

    public getCaseContact(caseContactId: number): Observable<CaseContactDto> {
        return this._api.get<CaseContactDto>(`casefile/v1/case-contacts/${caseContactId}`);
    }

    public updateCaseContact(caseContactId: number, caseContactDto: CaseContactDto): Observable<CaseContactDto> {
        return this._api.put<CaseContactDto>(`casefile/v1/case-contacts/${caseContactId}`, caseContactDto);
    }

    public deleteCaseContact(caseContactId: number): Observable<void> {
        return this._api.delete<void>(`casefile/v1/case-contacts/${caseContactId}`);
    }

    public setRelationship(caseContactId: number, relationshipTypeId: number): Observable<CaseContactDto> {
        return this._api.put<CaseContactDto>(`casefile/v1/case-contacts/relationships/${caseContactId}/${relationshipTypeId}`, null);
    }

    public removeRelationship(caseContactId: number): Observable<CaseContactDto> {
        return this._api.delete<CaseContactDto>(`casefile/v1/case-contacts/relationships/${caseContactId}`);
    }
}
