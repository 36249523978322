import { Pipe, PipeTransform } from '@angular/core';

import { ContactAttributeDto } from '../dtos';

@Pipe({
    name: 'formatUrl',
})
export class FormatUrlPipe implements PipeTransform {
    public transform(value: ContactAttributeDto['value']): string {
        if (!value) {
            return '';
        }
        if (URL.canParse(value)) {
            return value;
        }

        const formattedValue = `https://${value}`;
        if (URL.canParse(formattedValue)) {
            return formattedValue;
        }

        return value;
    }
}
