import { Pipe, PipeTransform } from '@angular/core';

import { ContactEntity } from '../dtos';

@Pipe({
    name: 'contactFullName',
})
export class ContactFullNamePipe implements PipeTransform {
    public transform<T extends ContactEntity>(contact: T): string {
        return `${contact.firstName} ${contact.lastName} ${contact.contactId}`;
    }
}
