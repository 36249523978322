import { Pipe, PipeTransform } from '@angular/core';

import { ContactAttributeDto } from '../dtos';

@Pipe({
    name: 'emailContactAttr',
})
export class EmailPipe implements PipeTransform {
    public transform(attributes: ContactAttributeDto[] = []): ContactAttributeDto['value'] {
        const emailAttribute = attributes.find((attribute) => attribute.attributeType === 'email' && attribute.attributeLabel === 'Main');
        return emailAttribute ? emailAttribute.value : 'No Email';
    }
}
