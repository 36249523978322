import { Pipe, PipeTransform } from '@angular/core';

import { UserRoles } from '../dtos';

@Pipe({
    name: 'canEditRole',
})
export class CanEditRolePipe implements PipeTransform {
    public transform(roles: UserRoles[] | undefined): boolean {
        return !roles?.includes(UserRoles.DIRECTOR);
    }
}
