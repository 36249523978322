import { Pipe, PipeTransform } from '@angular/core';

import { ContactAttributeDto } from '../dtos';

@Pipe({
    name: 'customEmail',
})
export class CustomEmailPipe implements PipeTransform {
    public transform(attributes: ContactAttributeDto[]): ContactAttributeDto['value'] {
        const emailAttribute = attributes.find((attribute) => attribute.attributeType === 'email');
        return emailAttribute ? emailAttribute.value : 'No Email';
    }
}
