import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'firstChar',
})
export class FirstCharPipe implements PipeTransform {
    public transform(value: string | undefined | null): string {
        return value?.charAt(0) ?? '';
    }
}
