import { HttpClient, HttpEvent } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import {
    CaseFileDto,
    Page,
    CaseFileNoteDto,
    FileViewDto,
    AttachementDto,
    CloseCaseFileDto,
    CaseFileCountDto,
    UserWebDto,
} from '../../../dtos';

export class CaseFileResource {
    private readonly _api = inject(HttpClient);

    public createCaseFile(caseFileDto: CaseFileDto): Observable<CaseFileDto> {
        return this._api.post<CaseFileDto>(`casefile/v1/case-files`, caseFileDto);
    }

    public getCaseFiles(params: {
        locationId: number;
        page?: number;
        size?: number;
        searchText?: string;
        status?: string;
        type?: string;
    }): Observable<Page<CaseFileDto>> {
        return this._api.get<Page<CaseFileDto>>(`casefile/v1/case-files`, { params: { ...params } });
    }

    public getStarredCaseFiles(params: { locationId: number; page?: number; size?: number }): Observable<Page<CaseFileDto>> {
        return this._api.get<Page<CaseFileDto>>(`casefile/v1/case-files/starred`, { params: { ...params } });
    }

    public getCaseFilesCounts(locationId: number): Observable<CaseFileCountDto[]> {
        return this._api.get<CaseFileCountDto[]>(`casefile/v1/case-files/counts`, { params: { locationId } });
    }

    public getCaseFile(
        caseFileId: number,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileDto> {
        return this._api.get<CaseFileDto>(`casefile/v1/case-files/${caseFileId}`, { params: { ...params } });
    }

    public updateCaseFile(
        caseFileId: number,
        caseFileDto: CaseFileDto,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileDto> {
        return this._api.put<CaseFileDto>(`casefile/v1/case-files/${caseFileId}`, caseFileDto, { params: { ...params } });
    }

    public deleteCaseFile(caseFileId: number, params: { locationId: number }): Observable<void> {
        return this._api.delete<void>(`casefile/v1/case-files/${caseFileId}`, { params: { ...params } });
    }

    public toggleCaseFileStatusToOpen(caseFileId: number, params: { locationId: number }): Observable<CaseFileDto> {
        return this._api.put<CaseFileDto>(`casefile/v1/case-files/toggle-open/${caseFileId}`, null, { params: { ...params } });
    }

    public toggleCaseFileStatusToClose(caseFileId: number, closeCaseFile: CloseCaseFileDto): Observable<CaseFileDto> {
        return this._api.put<CaseFileDto>(`casefile/v1/case-files/toggle-close/${caseFileId}`, closeCaseFile);
    }

    public setStarredCaseFile(caseFileId: number, params: { locationId: number }): Observable<CaseFileDto> {
        return this._api.put<CaseFileDto>(`casefile/v1/case-files/toggle-starred/${caseFileId}`, null, { params: { ...params } });
    }

    public moveHomeLocation(
        caseFileId: number,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileDto> {
        return this._api.put<CaseFileDto>(`casefile/v1/case-files/home-location/${caseFileId}/${params.locationId}`, null, {
            params: { ...params },
        });
    }

    public deleteCaseFileNote(noteId: number, params: { locationId: number }): Observable<void> {
        return this._api.delete<void>(`casefile/v1/case-files/notes/${noteId}`, { params: { ...params } });
    }

    public updateCaseFileNote(
        noteId: number,
        caseFileNoteDto: CaseFileNoteDto,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileNoteDto> {
        return this._api.put<CaseFileNoteDto>(`casefile/v1/case-files/notes/${noteId}`, caseFileNoteDto, { params: { ...params } });
    }

    public createCaseFileNote(
        caseFileId: number,
        caseFileNoteDto: CaseFileNoteDto,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileDto> {
        return this._api.post<CaseFileDto>(`casefile/v1/case-files/notes/${caseFileId}`, caseFileNoteDto, { params: { ...params } });
    }

    public grantAccess(
        caseFileId: number,
        userId: number,
        params: {
            locationId: number;
        }
    ): Observable<UserWebDto[]> {
        return this._api.post<UserWebDto[]>(`casefile/v1/case-files/grant-access/${caseFileId}/${userId}`, null, { params: { ...params } });
    }

    public revokeAccess(
        caseFileId: number,
        userId: number,
        params: {
            locationId: number;
        }
    ): Observable<UserWebDto[]> {
        return this._api.delete<UserWebDto[]>(`casefile/v1/case-files/revoke-access/${caseFileId}/${userId}`, {
            params: { ...params },
        });
    }

    public getAccessList(
        caseFileId: number,
        params: {
            locationId: number;
        }
    ): Observable<UserWebDto[]> {
        return this._api.get<UserWebDto[]>(`casefile/v1/case-files/access-list/${caseFileId}`, { params: { ...params } });
    }

    public getLocationProvidersWithoutCaseFileAccess(
        caseFileId: number,
        params: {
            locationId: number;
        }
    ): Observable<CaseFileDto> {
        return this._api.get<CaseFileDto>(`casefile/v1/case-files/access-list/providers/${caseFileId}`, { params: { ...params } });
    }

    public getAppointmentNotes(appointmentId: number, params: { locationId: number }): Observable<CaseFileNoteDto[]> {
        return this._api.get<CaseFileNoteDto[]>(`casefile/v1/case-files/appointments/${appointmentId}/notes`, { params: { ...params } });
    }

    public getAttachementsList(caseFileId: number): Observable<AttachementDto[]> {
        return this._api.get<AttachementDto[]>(`casefile/v1/case-files/attachments/${caseFileId}`);
    }

    public uploadAttachement(caseFileId: number, file: File): Observable<HttpEvent<AttachementDto[]>> {
        const formData = new FormData();
        formData.append('file', file, file.name);

        return this._api.post<AttachementDto[]>(`casefile/v1/case-files/attachments/${caseFileId}`, formData, {
            reportProgress: true,
            observe: 'events',
        });
    }

    public viewAttachement(attachementId: number): Observable<FileViewDto> {
        return this._api.get<FileViewDto>(`casefile/v1/case-files/attachments/content-view/${attachementId}`);
    }

    public downloadAttachement(attachementId: number): Observable<Blob> {
        return this._api.get(`casefile/v1/case-files/attachments/content-download/${attachementId}`, { responseType: 'blob' });
    }

    public deleteAttachement(attachementId: number): Observable<AttachementDto[]> {
        return this._api.delete<AttachementDto[]>(`casefile/v1/case-files/attachments/${attachementId}`);
    }
}
