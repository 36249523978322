import { StorageAuditAction } from '../enums/StorageAuditAction';

export class StorageAuditDto {
    public userId: undefined | number;
    public auditAction: undefined | StorageAuditAction;
    public timestamp: undefined | number; // Date

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}
}

// TODO add entity
export class StorageDto {
    public fileId: undefined | number;
    public storageId: undefined | number;
    public userId: undefined | number;
    public name: undefined | string;
    public contentType: undefined | string;
    public fileType: undefined | string;
    public created: undefined | number; // Date;
    public modified: undefined | number; // Date;
    public size: undefined | number;
    public audit: undefined | StorageAuditDto[];

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    protected constructor() {}
}
