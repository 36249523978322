import { TeamMemberFilesDto } from '../../team';
import { StorageDto } from '../../tentant-platform';

export enum FileViewMode {
    Tab = 'TAB',
    Viewer = 'VIEWER',
}

export interface UploadFileData {
    file: File;
    entityId: number;
}

export interface ViewFileData {
    fileId: number | undefined;
    viewMode: FileViewMode;
}

export class AttachementUploading {
    public uploadingId: number | undefined | null;
    public attachementName: string | undefined | null;
    public uploadingProgress: number | undefined | null;
}

export class AttachementDto extends StorageDto {
    public caseFileStorageId: number | undefined | null;

    public static fromTeamFile(teamFile: TeamMemberFilesDto): AttachementDto {
        const attachement = new AttachementDto();
        const storage = teamFile.storageDto;

        attachement.caseFileStorageId = null;
        attachement.storageId = storage?.storageId;
        attachement.fileId = teamFile.fileId;
        attachement.audit = storage?.audit;
        attachement.contentType = storage?.contentType;
        attachement.created = storage?.created;
        attachement.fileType = storage?.fileType;
        attachement.modified = storage?.modified;
        attachement.name = storage?.name;
        attachement.size = storage?.size;
        attachement.userId = storage?.userId;

        return attachement;
    }
}
