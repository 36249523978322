import { HttpClient, HttpEvent } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

import {
    GetTeamMembersParams,
    GrandSystemAccess,
    Page,
    TeamMemberDto,
    TeamMemberEntity,
    TeamMemberFilesDto,
    TeamMemberImageDto,
    TeamMemberLicenseDto,
    TeamMemberTextDto,
    UploadFileData,
} from '../../../dtos';

export class TeamMemberResource {
    private readonly _api = inject(HttpClient);

    public getTeamMember(teamMemberId: number | null | undefined): Observable<TeamMemberEntity> {
        return this._api.get<TeamMemberEntity>(`team/v1/team-members/${teamMemberId}`);
    }

    public getTeamMembers(params?: GetTeamMembersParams): Observable<Page<TeamMemberEntity>> {
        return this._api.get<Page<TeamMemberEntity>>('team/v1/team-members', { params: { ...params } });
    }

    public createTeamMember(dto: TeamMemberDto): Observable<TeamMemberEntity> {
        return this._api.post<TeamMemberEntity>(`team/v1/team-members`, dto);
    }

    public updateTeamMember(teamMemberId: number, dto: TeamMemberDto): Observable<TeamMemberEntity> {
        return this._api.put<TeamMemberEntity>(`team/v1/team-members/${teamMemberId}`, dto);
    }

    public toggleTeamMemberStatus(teamMemberId: number): Observable<TeamMemberEntity> {
        return this._api.put<TeamMemberEntity>(`team/v1/team-members/toggle-enable/${teamMemberId}`, null);
    }

    public addProfession(teamMemberId: number, profession: TeamMemberLicenseDto): Observable<TeamMemberEntity> {
        return this._api.post<TeamMemberEntity>(`team/v1/team-members/college-registrations/${teamMemberId}`, profession);
    }

    public deleteProfession(licenseId: number): Observable<void> {
        return this._api.delete<void>(`team/v1/team-members/college-registrations/${licenseId}`);
    }

    public editProfession(license: TeamMemberLicenseDto): Observable<TeamMemberDto> {
        return this._api.put<TeamMemberDto>(`team/v1/team-members/college-registrations/${license.memberLicenseId}`, license);
    }

    public deleteTeamMember(teamMemberId: number): Observable<void> {
        return this._api.delete<void>(`team/v1/team-members/${teamMemberId}`);
    }

    public getLicenses(teamMemberId: number): Observable<TeamMemberFilesDto[]> {
        return this._api.get<TeamMemberFilesDto[]>(`team/v1/team-members/files/${teamMemberId}`);
    }

    public uploadLicense(uploadFileData: UploadFileData): Observable<HttpEvent<TeamMemberFilesDto[]>> {
        const formData: FormData = new FormData();
        formData.append('file', uploadFileData.file, uploadFileData.file.name);

        return this._api.post<TeamMemberFilesDto[]>(`team/v1/team-members/files/${uploadFileData.entityId}`, formData, {
            reportProgress: true,
            observe: 'events',
        });
    }

    public deleteLicense(fileId: number): Observable<TeamMemberFilesDto[]> {
        return this._api.delete<TeamMemberFilesDto[]>(`team/v1/team-members/files/${fileId}`);
    }

    public downloadFile(fileId: number): Observable<Blob> {
        return this._api.get(`team/v1/team-members/files/content-download/${fileId}`, { responseType: 'blob' });
    }

    public viewImageFile(fileId: number): Observable<TeamMemberImageDto> {
        return this._api.get<TeamMemberImageDto>(`team/v1/team-members/files/content-view/${fileId}`);
    }

    public viewTextFile(fileId: number): Observable<TeamMemberTextDto> {
        return this._api.get<TeamMemberTextDto>(`team/v1/team-members/files/content-view/${fileId}`);
    }

    public grantSystemAccess(params: GrandSystemAccess): Observable<TeamMemberEntity> {
        if (typeof params.userId !== 'undefined') {
            return this._api.post<TeamMemberEntity>(
                `team/v1/team-members/grant-system-access/${params.memberId}/${params.userId}`,
                params.userDto
            );
        } else {
            return this._api.post<TeamMemberEntity>(`team/v1/team-members/grant-system-access/${params.memberId}`, params.userDto);
        }
    }
}
