import { Pipe, PipeTransform } from '@angular/core';

import { ContactAttributeDto } from '../dtos';

@Pipe({
    name: 'websiteContactAttr',
})
export class WebsitePipe implements PipeTransform {
    public transform(attributes: ContactAttributeDto[] = []): ContactAttributeDto['value'] {
        const websiteAttribute = attributes.find(
            (attribute) => attribute.attributeType === 'website' && attribute.attributeLabel === 'Company site'
        );
        return websiteAttribute ? websiteAttribute.value : 'No Website';
    }
}
