export const MIN_PASSWORD_LENGTH: number = 8;

/**
 * Escapes a string for usage within a regular expression
 */
function escapeRegExp(string: string): string {
    // $& means the whole matched string
    /// return string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

interface PasswordRequirement {
    name: string;

    testRule(value: string): boolean;
}

interface PasswordRequirementWithChars extends PasswordRequirement {
    genChars(count: number): string;
}

const passwordRequirements: Array<PasswordRequirement | PasswordRequirementWithChars> = [
    {
        name: 'must contain a lowercase character',
        testRule: (value: string): boolean => /[a-z]/.test(value),
        genChars: (count: number): string => generateChars('abcdefghijklmnopqrstuvwxyz', count),
    },
    {
        name: 'must contain an uppercase character',
        testRule: (value: string): boolean => /[A-Z]/.test(value),
        genChars: (count: number): string => generateChars('ABCDEFGHIJKLMNOPQRSTUVWXYZ', count),
    },
    {
        name: 'must contain a digit 0-9',
        testRule: (value: string): boolean => /\d/.test(value),
        genChars: (count: number): string => generateChars('0123456789', count),
    },
    {
        name: 'must contain a symbol ( . , ! ( ) @ % # $ & * ^ – _ )',
        testRule: (value: string): boolean => new RegExp(escapeRegExp('.,!()@%#$&*^–_')).test(value),
        genChars: (count: number): string => generateChars('.,!()@%#$&*^–_', count),
    },
    {
        name: 'must be at least 8 characters long',
        testRule: (value: string): boolean => value.length >= MIN_PASSWORD_LENGTH,
    },
];

function getSeedRanges(totalLength: number, rangesCount: number): number[] {
    const baseSeed: number = Math.floor(totalLength / rangesCount);

    // If the division is exact, return the array
    if (totalLength % rangesCount === 0) {
        return new Array(rangesCount).fill(baseSeed);
    }

    // Otherwise, distribute the remainder to the array elements one by one
    const result: number[] = new Array(rangesCount).fill(baseSeed);
    let remainder = totalLength - baseSeed * rangesCount;

    for (let i = 0; remainder > 0; i = (i + 1) % rangesCount) {
        result[i]++;
        remainder--;
    }

    return result;
}

function generateChars(chars: string, length: number): string {
    let _chars: string = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        _chars += chars[randomIndex];
    }
    return _chars;
}

function shuffleString(str: string): string {
    const chars: string[] = str.split('');
    for (let i = chars.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [chars[i], chars[j]] = [chars[j], chars[i]];
    }
    return chars.join('');
}

export function generatePassword(length: number = 16): string {
    const genRequirements: PasswordRequirementWithChars[] = passwordRequirements.filter(
        (r: PasswordRequirement | PasswordRequirementWithChars): r is PasswordRequirementWithChars => 'genChars' in r
    );
    const seedRanges: number[] = getSeedRanges(length, genRequirements.length);
    return shuffleString(genRequirements.map((r: PasswordRequirementWithChars, index: number) => r.genChars(seedRanges[index])).join(''));
}
