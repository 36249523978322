import { Pipe, PipeTransform } from '@angular/core';

import { ContactAttributeDto } from '../dtos';

@Pipe({
    name: 'abbreviationCode',
})
export class AbbreviationCodePipe implements PipeTransform {
    public static transform(attributes: ContactAttributeDto[] = []): ContactAttributeDto['value'] {
        const abbreviationCodeAttribute = attributes.find((attribute) => attribute.attributeType === 'abbreviationCode');
        return abbreviationCodeAttribute ? abbreviationCodeAttribute.value : '';
    }

    public transform(attributes?: ContactAttributeDto[]): ContactAttributeDto['value'] {
        return AbbreviationCodePipe.transform(attributes);
    }
}
