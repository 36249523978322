import { RelationshipTypeDto } from './RelationshipTypeDto';
import { ContactAddressDto, ContactAttributeDto, ContactDto } from '../../contact';

interface CreateConfig {
    caseFileId: number;
    prefix: string | null;
    firstName: string | null;
    lastName: string | null;
    middleName: string | null;
    preferredName: string | null;
    suffix: string | null;
    jobTitle: string | null;
    companyName: string | null;
    birthday: number | null;
    gender: string | null;
    addresses: ContactAddressDto[];
    attributes: ContactAttributeDto[];
}

interface EditConfig {
    contactId: number;
    caseFileId: number;
    caseContactId: number | null;
    prefix: string | null;
    firstName: string | null;
    lastName: string | null;
    middleName: string | null;
    preferredName: string | null;
    suffix: string | null;
    jobTitle: string | null;
    companyName: string | null;
    birthday: number | null;
    gender: string | null;
    addresses: ContactAddressDto[];
    attributes: ContactAttributeDto[];
    relationshipType: undefined | RelationshipTypeDto;
}

export class CaseContactDto extends ContactDto {
    public caseContactId: undefined | number | null;
    public caseFileId: undefined | number;
    public override contactId: undefined | number;
    public relationshipType: undefined | RelationshipTypeDto;

    public static getCreateObject(config: Partial<CreateConfig>): CaseContactDto {
        const caseContact = new CaseContactDto();

        caseContact.prefix = config.prefix;
        caseContact.caseFileId = config.caseFileId;
        caseContact.firstName = config.firstName;
        caseContact.lastName = config.lastName;
        caseContact.middleName = config.middleName;
        caseContact.preferredName = config.preferredName;
        caseContact.suffix = config.suffix;
        caseContact.jobTitle = config.jobTitle;
        caseContact.companyName = config.companyName;
        caseContact.birthday = config.birthday;
        caseContact.gender = config.gender;
        caseContact.addresses = config.addresses;
        caseContact.attributes = config.attributes;

        return caseContact;
    }

    public static getEditObject(config: Partial<EditConfig>): CaseContactDto {
        const caseContact = new CaseContactDto();

        caseContact.contactId = config.contactId;
        caseContact.caseContactId = config.caseContactId;
        caseContact.caseFileId = config.caseFileId;
        caseContact.prefix = config.prefix;
        caseContact.firstName = config.firstName;
        caseContact.lastName = config.lastName;
        caseContact.middleName = config.middleName;
        caseContact.preferredName = config.preferredName;
        caseContact.suffix = config.suffix;
        caseContact.jobTitle = config.jobTitle;
        caseContact.companyName = config.companyName;
        caseContact.birthday = config.birthday;
        caseContact.gender = config.gender;
        caseContact.addresses = config.addresses;
        caseContact.attributes = config.attributes;
        caseContact.relationshipType = config.relationshipType;

        return caseContact;
    }
}
