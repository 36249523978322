import { AbstractControl, ValidationErrors, ValidatorFn, FormArray, FormGroup } from '@angular/forms';

import { MaxLength, MinLength, ValidationExpressions } from '../utils';

export namespace CustomValidators {
    export function conditionalEmailValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && control.value.trim() !== '') {
                return ValidationExpressions.EMAIL_MASK.test(control.value) ? null : { email: true };
            }
            return null;
        };
    }

    export function emptyFormValidator(): ValidatorFn {
        const ignoredFields = ['label', 'type'];

        return (control: AbstractControl): ValidationErrors | null => {
            const formGroup = control as FormGroup;
            const controls = formGroup.controls;

            const isAnyFieldFilled = Object.keys(controls).some((key) => {
                const control = controls[key];
                if (control instanceof FormArray) {
                    return control.controls.some((arrayControl) => {
                        const value = arrayControl.value;
                        return Object.keys(value).some((fieldKey) => {
                            if (ignoredFields.includes(fieldKey)) {
                                return false;
                            }
                            const fieldValue = value[fieldKey];
                            return typeof fieldValue === 'string' && fieldValue.trim() !== '';
                        });
                    });
                } else if (control instanceof FormGroup) {
                    return Object.keys(control.controls).some((fieldKey) => {
                        if (ignoredFields.includes(fieldKey)) {
                            return false;
                        }
                        const fieldControl = control.controls[fieldKey];
                        const value = fieldControl.value;
                        return typeof value === 'string' && value.trim() !== '';
                    });
                } else {
                    const value = control.value;
                    return (typeof value === 'string' && value.trim() !== '') || (value instanceof Date && !isNaN(value.getTime()));
                }
            });

            return isAnyFieldFilled ? null : { formIsNotEmpty: true };
        };
    }

    export function urlValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value || value.trim() === '') {
                return null;
            }
            if (URL.canParse(value)) {
                return null;
            }

            return { invalidUrl: true };
        };
    }

    export function upcValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (!value) {
                return null;
            }

            const valueLength = String(value).length;

            if (valueLength >= MinLength.BILLING_ITEM_UPC_MIN_LENGTH && valueLength <= MaxLength.BILLING_ITEM_UPC_MAX_LENGTH) {
                return null;
            }

            return { invalidLength: true };
        };
    }

    export function rateValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (!value) {
                return null;
            }

            const valueLength = String(value).length;

            if (valueLength <= MaxLength.BILLING_ITEM_RATE_MAX_LENGTH) {
                return null;
            }

            return { invalidLength: true };
        };
    }
}
