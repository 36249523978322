import { FormArray, FormGroup } from '@angular/forms';

import { ContactAttributeDto } from '../dtos';

export const processAttributes = (form: FormGroup, attributeTypeMap: Record<string, string>): ContactAttributeDto[] =>
    Object.entries(attributeTypeMap).reduce((acc, [type, formArrayName]) => {
        const formArray = form.get(formArrayName) as FormArray;
        formArray.controls.forEach((control, index) => {
            const value = control.value[type]?.trim();
            if (value) {
                acc.push({
                    value,
                    date: new Date().toISOString(),
                    attributeType: control.value['type']?.trim() ?? null,
                    attributeLabel: control.value['label']?.trim() ?? null,
                    primary: index === 0,
                });
            }
        });
        return acc;
    }, [] as ContactAttributeDto[]);
