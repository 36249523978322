import { Pipe, PipeTransform } from '@angular/core';

import { ContactGroupsDto } from '../dtos';

@Pipe({
    name: 'hasReservedGroup',
})
export class HasReservedGroupPipe implements PipeTransform {
    public transform(groups: ContactGroupsDto[] | undefined): boolean {
        return groups?.some((group) => 'reserved' in group && group.reserved) ?? false;
    }
}
